@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto+Mono:wght@400&display=swap);
html {
  scroll-behavior: smooth;
}
body {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
}
.robotoMono {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}
a {
  color: black;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: black;
}
p {
  margin-bottom: 0;
}

/*------ PROYECTS TITLE ------*/
.proyectsTitleDiv {
  padding-left: 15%;
  padding-top: 40px;
  padding-bottom: 2%;
  background-color: #613659;
  color: white;
  text-transform: uppercase;
}
.proyectsTitle {
  font-size: 7vw;
  margin-bottom: 0;
}

/*------ PROYECTS CARROUSEL CONTAINER ------*/
.ProyectsCarrouselContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-left: 4%;
  width: 100%;
  padding-top: 20px;
  background-color: #613659;
}

/*------ PROYECT CONTAINER ------*/
.ProyectContainer {
  width: 320px;
  height: 384px;
  margin-right: 72px;
  margin-bottom: 72px;
  background-color: #ffe3f5;
  box-shadow: 5px 5px 1px white;
}
.ProyectContainer:hover {
  width: 320px;
  height: 384px;
  margin-right: 72px;
  margin-bottom: 72px;
  background-color: #ffe3f5;
  transition: 0.3s;
  transform: scale(1.05);
  box-shadow: 15px 15px 1px white;
}

.proyectImage {
  height: 55%;
  width: 100%;
  margin-bottom: 16px;
  cursor: pointer;
  filter: grayscale(100%);
  transition: 0.5s;
}
.proyectImage:hover {
  height: 55%;
  width: 100%;
  margin-bottom: 16px;
  cursor: pointer;
  filter: none;
}
.cardItemsDiv {
  margin-left: 15px;
  margin-right: 20px;
  height: auto;
  background-color: #ebc6de;
}
.cardFonts {
  font-size: 1.6em;
}
.gitHubLink {
  background-color: rgba(76, 175, 80, 0);
  color: black;
  background-color: #a28192;
  border: 2px solid #a28192;
  margin-top: 1px;
  padding: 6px 14px;
  text-align: center;
  text-decoration: none;
  font-size: 0.9em;
  transition-duration: 0.4s;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
}

.gitHubLink:hover {
  border: 2px solid #d3b1c2;
  color: white;
  background-color: rgba(253, 245, 230, 0);
  margin-top: 1px;
  padding: 6px 14px;
  text-transform: uppercase;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /*------ PROYECTS TITLE ------*/
  .proyectsTitleDiv {
    padding-top: 25px;
    padding-left: 10%;
  }
  /*------ PROYECTS CARROUSEL CONTAINER ------*/
  .ProyectsCarrouselContainer {
    justify-content: space-evenly;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 10px;
    width: 100%;
    background-color: #613659;
  }
  /*------ PROYECT CONTAINER ------*/
  .ProyectContainer {
    transform: scale(0.9);
    margin: 0;
    margin-bottom: 25px;
  }
  .ProyectContainer:hover {
    width: 320px;
    height: 384px;
    margin-right: 0px;
    margin-bottom: 72px;
    background-color: #ffe3f5;
    transition: 0.3s;
    transform: scale(1.05);
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .proyectsTitleDiv {
    padding-bottom: 20px;
  }
  .proyectsTitle {
    font-size: 90px;
    margin-bottom: 0;
  }
}

/*------ ABOUT ME CONTAINER ------*/
.AboutMeContainer {
  display: flex;
  margin-top: 40px;
  margin-bottom: 90px;
}

/*------ PRESENTATION TEXT  ------*/
.presentationText {
  border-left: 1px solid black;
  padding-left: 30px;
  padding-right: 10%;
  width: 85%;
  font-family: "Montserrat", sans-serif;
  font-size: 0.9em;
}
.titleBox {
  width: 15%;
}

.linkedInButton {
  background-color: #0a66c2; /* Green */
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1em;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: white;
  color: black;
  border: 2px solid #d3b1c2;
  margin-top: 20px;
}

.linkedInButton:hover {
  background-color: #d3b1c2;
  color: white;
}
@media only screen and (max-width: 600px) {
  .titleBox {
    width: 30px;
  }
}

.footerDiv {
  border-top: 1px solid black;
  display: flex;
  justify-content: space-between;
}
.footerLinks {
  text-transform: uppercase;
}
.footerContactDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footerLinks:hover {
  color: #a28192;
  font-weight: bold;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .footerDiv {
    padding-top: 2%;
    padding-left: 1%;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 1%;
    margin-top: 18%;
  }
  .footerTitle {
    font-size: 7vw;
    letter-spacing: 1px;
  }
  .footerLinks {
    font-size: 4vw;
    letter-spacing: 2px;
    display: inline-block;
  }
  .footerLogo {
    align-self: flex-end;
    text-align: end;
    font-size: 3.55vw;
    letter-spacing: 3px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .footerDiv {
    padding-top: 2%;
    padding-left: 1%;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 1%;
    margin-top: 18%;
  }
  .footerTitle {
    font-size: 6vw;
    letter-spacing: 1px;
  }
  .footerLinks {
    font-size: 3vw;
    letter-spacing: 2px;
  }
  .footerLogo {
    align-self: flex-end;
    text-align: end;
    font-size: 2.5vw;
    letter-spacing: 3px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .footerDiv {
    margin-top: 12%;
  }
  .footerTitle {
    font-size: 4.8vw;
    letter-spacing: 1px;
  }
  .footerLinks {
    font-size: 2vw;
    letter-spacing: 2px;
  }
  .footerLogo {
    align-self: flex-end;
    text-align: end;
    font-size: 1.8vw;
    letter-spacing: 3px;
  }
}
/* Large devices (laptops/desktops/IPAD, 992px and up) */
@media only screen and (min-width: 992px) {
  .footerDiv {
    padding-top: 1%;
    padding-left: 1%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .footerTitle {
    font-size: 3.5vw;
    letter-spacing: 1px;
  }
  .footerLinks {
    font-size: 1.8vw;
    letter-spacing: 2px;
  }
  .footerLogo {
    align-self: flex-end;
    text-align: end;
    font-size: 1.7vw;
    letter-spacing: 3px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .footerDiv {
    padding-top: 1%;
    padding-left: 1%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .footerTitle {
    font-size: 3vw;
    letter-spacing: 1px;
  }
  .footerLinks {
    font-size: 1.5vw;
    letter-spacing: 2px;
    display: inline-block;
    margin-top: 5px;
  }
  .footerLogo {
    align-self: flex-end;
    text-align: end;
    font-size: 1.6vw;
    letter-spacing: 3px;
  }
}
@media only screen and (min-width: 1450px) {
  .footerDiv {
    padding-top: 1%;
    padding-left: 0;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 4%;
  }
  .footerTitle {
    font-size: 2vw;
    letter-spacing: 1px;
  }
  .footerLinks {
    font-size: 1vw;
    letter-spacing: 2px;
    display: inline-block;
    margin-top: 5px;
  }
  .footerLogo {
    align-self: flex-end;
    text-align: end;
    font-size: 1vw;
    letter-spacing: 3px;
  }
}

.navBarDiv {
  margin-bottom: 5%;
  margin-top: 3%;
  padding-left: 15%;
}
.navBarLeyend {
  padding-left: 6%;
}
.navBarH1 {
  animation: fadeIn 5s;
}
@media only screen and (max-width: 600px) {
  .navBarDiv {
    margin-bottom: 0;
    margin-top: 3%;
    padding-left: 10%;
  }
  .navBarH1 {
    font-size: 7vw;
  }
}

@media only screen and (max-width: 700px) {
  .navBarDiv {
    margin-top: 20px;
  }
  .navBarLeyend {
    padding-left: 0;
  }
}

.fade-in-bottom {
  animation: fade-in-bottom 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.fade-in-top {
  animation: fade-in-top 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.fade-in-right {
  animation: fade-in-right 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
.tracking-in-expand {
  animation: tracking-in-expand 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
.fade-in {
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@keyframes fade-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */
@keyframes fade-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in-right
 * ----------------------------------------
 */
@keyframes fade-in-right {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

