.footerDiv {
  border-top: 1px solid black;
  display: flex;
  justify-content: space-between;
}
.footerLinks {
  text-transform: uppercase;
}
.footerContactDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footerLinks:hover {
  color: #a28192;
  font-weight: bold;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .footerDiv {
    padding-top: 2%;
    padding-left: 1%;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 1%;
    margin-top: 18%;
  }
  .footerTitle {
    font-size: 7vw;
    letter-spacing: 1px;
  }
  .footerLinks {
    font-size: 4vw;
    letter-spacing: 2px;
    display: inline-block;
  }
  .footerLogo {
    align-self: flex-end;
    text-align: end;
    font-size: 3.55vw;
    letter-spacing: 3px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .footerDiv {
    padding-top: 2%;
    padding-left: 1%;
    margin-left: 2%;
    margin-right: 2%;
    margin-bottom: 1%;
    margin-top: 18%;
  }
  .footerTitle {
    font-size: 6vw;
    letter-spacing: 1px;
  }
  .footerLinks {
    font-size: 3vw;
    letter-spacing: 2px;
  }
  .footerLogo {
    align-self: flex-end;
    text-align: end;
    font-size: 2.5vw;
    letter-spacing: 3px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .footerDiv {
    margin-top: 12%;
  }
  .footerTitle {
    font-size: 4.8vw;
    letter-spacing: 1px;
  }
  .footerLinks {
    font-size: 2vw;
    letter-spacing: 2px;
  }
  .footerLogo {
    align-self: flex-end;
    text-align: end;
    font-size: 1.8vw;
    letter-spacing: 3px;
  }
}
/* Large devices (laptops/desktops/IPAD, 992px and up) */
@media only screen and (min-width: 992px) {
  .footerDiv {
    padding-top: 1%;
    padding-left: 1%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .footerTitle {
    font-size: 3.5vw;
    letter-spacing: 1px;
  }
  .footerLinks {
    font-size: 1.8vw;
    letter-spacing: 2px;
  }
  .footerLogo {
    align-self: flex-end;
    text-align: end;
    font-size: 1.7vw;
    letter-spacing: 3px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .footerDiv {
    padding-top: 1%;
    padding-left: 1%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .footerTitle {
    font-size: 3vw;
    letter-spacing: 1px;
  }
  .footerLinks {
    font-size: 1.5vw;
    letter-spacing: 2px;
    display: inline-block;
    margin-top: 5px;
  }
  .footerLogo {
    align-self: flex-end;
    text-align: end;
    font-size: 1.6vw;
    letter-spacing: 3px;
  }
}
@media only screen and (min-width: 1450px) {
  .footerDiv {
    padding-top: 1%;
    padding-left: 0;
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 4%;
  }
  .footerTitle {
    font-size: 2vw;
    letter-spacing: 1px;
  }
  .footerLinks {
    font-size: 1vw;
    letter-spacing: 2px;
    display: inline-block;
    margin-top: 5px;
  }
  .footerLogo {
    align-self: flex-end;
    text-align: end;
    font-size: 1vw;
    letter-spacing: 3px;
  }
}
