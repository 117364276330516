@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto+Mono:wght@400&display=swap");

html {
  scroll-behavior: smooth;
}
body {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
}
.robotoMono {
  font-family: "Roboto Mono", monospace;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}
a {
  color: black;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: black;
}
p {
  margin-bottom: 0;
}
